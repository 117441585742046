jQuery(document).ready(function($){
	
	"use strict";	
	
		$('.qtyplus').click(function(e){
			e.preventDefault();
			var currentVal = parseInt($('input[name="quantity"]').val());
			if (!isNaN(currentVal)) {
				$('input[name="quantity"]').val(currentVal + 1);
			} else {
				$('input[name="quantity"]').val(0);
			}
		});
	
		$(".qtyminus").click(function(e) {
	
			e.preventDefault();
			var currentVal = parseInt($('input[name="quantity"]').val());
			if (!isNaN(currentVal) && currentVal > 0) {
				$('input[name="quantity"]').val(currentVal - 1);
			} else {
				$('input[name="quantity"]').val(0);
			}
		});
	
	
	$("html").niceScroll({zindex:99999,cursorborder:"1px solid #424242"});
	//STICKY MENU...
	$("#menu-container").sticky({ topSpacing: 0 });
	//Mobile Menu
	$("#dt-menu-toggle").click(function( event ){
		event.preventDefault();
		var $menu = $("nav#main-menu").find("ul.menu:first");
		$menu.slideToggle(function(){
			$menu.css('overflow' , 'visible');
			$menu.toggleClass('menu-toggle-open');
		});
	});

	function toggleMobileMenu($this)
	{
		if ($this.parent().hasClass('active'))
		{
			$this.children('.fal').toggleClass('fa-plus').toggleClass('fa-minus');

			if ($this.prev('ul').length)
			{
				$this.prev('ul').slideUp(400);
			}
			else
			{
				$this.prev('.megamenu-child-container').find('ul:first').slideUp(600);
			}
		}
		else
		{
			$this.children('.fal').toggleClass('fa-minus').toggleClass('fa-plus');

			if ($this.prev('ul').length)
			{
				$this.prev('ul').slideDown(400);
			}
			else
			{
				$this.prev('.megamenu-child-container').find('ul:first').slideDown(2000);
			}
		}
		
		$this.parent().toggleClass('active');
	}

	if ($('.dt-menu-expand').length)
	{
		$('.dt-menu-expand').each(function()
		{
			var $this = $(this);
			var $parent = $this.parent();

			$this.click(function(event)
			{
				event.preventDefault();
	
				toggleMobileMenu($(this));
	
				return false;
			});

			if ($parent.hasClass('current_page_item'))
			{
				$parent.toggleClass('active');

				$this.children('.fal').toggleClass('fa-minus').toggleClass('fa-plus');
			}

			if ($parent.children('a[href="javascript:;"]').length)
			{
				$parent.children('a[href="javascript:;"]').click(function(event)
				{
					event.preventDefault();
		
					toggleMobileMenu($this);
		
					return false;
				})
			}
		});
		/*$('.dt-menu-expand').click(function(event)
		{
			event.preventDefault();

			toggleMobileMenu($(this));

			return false;
		});*/
	}
  
	/*Menu */
	function megaMenu() {
		var screenWidth = $(document).width(),
		containerWidth = $("#header .container").width(),
		containerMinuScreen = (screenWidth - containerWidth)/2;
		
		if( containerWidth == screenWidth ){
							
			$("li.menu-item-megamenu-parent .megamenu-child-container").each(function(){
				var ParentLeftPosition = $(this).parent("li.menu-item-megamenu-parent").offset().left,
				MegaMenuChildContainerWidth = $(this).width();				
				
				if( (ParentLeftPosition + MegaMenuChildContainerWidth) > screenWidth ){
					var SwMinuOffset = screenWidth - ParentLeftPosition;
					var marginFromLeft = MegaMenuChildContainerWidth - SwMinuOffset;
					marginFromLeftActual = (marginFromLeft) + 25;
					marginLeftFromScreen = "-"+marginFromLeftActual+"px";
					$(this).css('left',marginLeftFromScreen);
			  }
			});		
				
		} else {
		
		
			$("li.menu-item-megamenu-parent .megamenu-child-container").each(function(){
				var ParentLeftPosition = $(this).parent("li.menu-item-megamenu-parent").offset().left,
				MegaMenuChildContainerWidth = $(this).width();
				
				if( (ParentLeftPosition + MegaMenuChildContainerWidth) > containerWidth ){
				
					var marginFromLeft = ( ParentLeftPosition + MegaMenuChildContainerWidth ) - screenWidth;
					var marginLeftFromContainer = containerMinuScreen + marginFromLeft + 20;
					
					if( MegaMenuChildContainerWidth > containerWidth ){
						
						var MegaMinuContainer	= ( (MegaMenuChildContainerWidth - containerWidth)/2 ) + 10; 			
						var marginLeftFromContainerVal = marginLeftFromContainer - MegaMinuContainer;
						marginLeftFromContainerVal = "-"+marginLeftFromContainerVal+"px";
						$(this).css('left',marginLeftFromContainerVal);
						
					} else {
						
						marginLeftFromContainer = "-"+marginLeftFromContainer+"px";
						$(this).css('left',marginLeftFromContainer);
					
					}
				
				}
			});
		
		}
	}
	
	
	megaMenu();
	$(window).smartresize(function(){
		megaMenu();
	});

	var isMobile = (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)) || (navigator.userAgent.match(/Android/i)) || (navigator.userAgent.match(/Blackberry/i)) || (navigator.userAgent.match(/Windows Phone/i)) ? true : false;
	var currentWidth = window.innerWidth || document.documentElement.clientWidth;

	//Menu Hover Start
	function menuHover() {
		$("li.menu-item-depth-0,li.menu-item-simple-parent ul li" ).hover(
			function(){
				if( $(this).find(".megamenu-child-container").length  ){
					$(this).find(".megamenu-child-container").stop().fadeIn('fast');
				} else {
					$(this).find("> ul.sub-menu").stop().fadeIn('fast');
				}
			},
			function(){
				if( $(this).find(".megamenu-child-container").length ){
					$(this).find(".megamenu-child-container").stop(true, true).hide();
				} else {
					$(this).find('> ul.sub-menu').stop(true, true).hide(); 
				}
			}
		);
	}//Menu Hover End

	if( !isMobile ){
		if( currentWidth > 767 ){
			menuHover();
		}
	}
	
		
	//MAIN MENU...
	$("#main-menu ul li:has(ul)").each(function(){
		$(this).addClass("hasSubmenu");
	});
	
	$(window).load(function() {
		
		//ISOTOPE CATEGORY...
		var $container = $('.dt-sc-portfolio-container');	
		var $gw = 16;
		
		$('.dt-sc-sorting-container a').click(function(){ 
			$('.dt-sc-sorting-container').find('a').removeClass('active-sort');
			$(this).addClass('active-sort');
			
			var selector = $(this).attr('data-filter');
			$container.isotope({
				filter: selector,
				animationOptions: {
					duration: 750,
					easing: 'linear',
					queue: false
				},
				masonry: {
					columnWidth: $('.dt-sc-portfolio-container .portfolio').width(),
					gutterWidth: $gw
				}
			});
			return false;
		});
		
		//ISOTOPE...
		if($container.length){
			$container.isotope({ 
				filter: '*',
				animationOptions: {
					duration: 750,
					easing: 'linear',
					queue: false
				},
				masonry: {
					columnWidth: $('.dt-sc-portfolio-container .portfolio').width(),
					gutterWidth: $gw
				}
			});
		}
				
	});

	//GOOGLE MAPS...
	/*var $map = $('#map');
	if( $map.length ) {
		$map.gMap({
			scrollwheel: false,
			address: 'No: 58 A, East Madison St, Baltimore, MD, USA',
			zoom: 16,
			markers: [ { 'address' : 'No: 58 A, East Madison St, Baltimore, MD, USA' } ]
		});
	}*/
	
	//PRETTYPHOTO...	
	var $pphoto = $('a[data-gal^="prettyPhoto[gallery]"]');
	if($pphoto.length){
		//PRETTYPHOTO...
		$("a[data-gal^='prettyPhoto[gallery]']").prettyPhoto({ 
			hook:'data-gal',
			show_title: false,
			social_tools: false,
			deeplinking: false
		});
	}
	
	var $bxslider;

	//GALLERY SLIDESHOW...
	if($(".add-slider, .portfolio-slider, .about-slider").length) {
		$bxslider = $('.add-slider, .portfolio-slider, .about-slider').bxSlider({
			auto:false, video:false, useCSS:false, pager:'true', autoHover:true, adaptiveHeight:true
		});

		if ($('.add-slider-slide').length && $bxslider.length)
		{
			$('.add-slider-slide').on('click', function(ev)
			{
				ev.preventDefault();

				if ($(this).data('slide') !== 'undefined')
				{
					$bxslider.goToSlide(parseInt($(this).data('slide')));
				}
			})
		}
	}
		
	//TWITTER TWEETS...
	/*$("#tweets_container").tweet({
		modpath: 'js/twitter/',
		username: "envato",
		count: 3,
		loading_text: "loading tweets...",
		join_text: '<i class="icon-twitter"></i>',
		template: "{join} {time}, {text}"
	});*/
	
	//GO TO TOP...
	var offset = 220;
	var duration = 500;
	$(window).scroll(function() {
		if ($(this).scrollTop() > offset) {
			$('.back-to-top').fadeIn(duration);
		} else {
			$('.back-to-top').fadeOut(duration);
		}
	});
	
	$('.back-to-top').click(function(event) {
		event.preventDefault();
		$('html, body').animate({scrollTop: 0}, duration);
		return false;
	});
	
	//NEWSLETTER AJAX SUBMIT...
	$('form[name="frmnewsletter"]').submit(function () {
		
		var This = $(this);
		if($(This).valid()) {
			var action = $(This).attr('action');

			var data_value = unescape($(This).serialize());
			$.ajax({
				 type: "POST",
				 url:action,
				 data: data_value,
				 error: function (xhr, status, error) {
					 confirm('The page save failed.');
				   },
				  success: function (response) {
					$('#ajax_subscribe_msg').html(response);
					$('#ajax_subscribe_msg').slideDown('slow');
					if (response.match('success') != null) $(This).slideUp('slow');
				 }
			});
		}
		return false;
		
    });
	$('form[name="frmnewsletter"]').validate({
		rules: { 
			mc_email: { required: true, email: true }
		},
		errorPlacement: function(error, element) {
			element.parent('p').addClass('error');
		}
	});

	//CONTACT FORM VALIDATION & MAIL SENDING....
	//if ($('form[name="frcontact"]').length)
	//{
		/*if (typeof $('form[name="frcontact"]').data('lang') !== 'undefined' && (parseInt($('form[name="frcontact"]').data('lang')) === 1 || $('form[name="frcontact"]').data('lang') === 'bg'))
		{
			$.extend($.validator.messages, {
				required: "Полето е задължително.",
				remote: "Моля, въведете правилната стойност.",
				email: "Моля, въведете валиден email.",
				url: "Моля, въведете валидно URL.",
				date: "Моля, въведете валидна дата.",
				dateISO: "Моля, въведете валидна дата (ISO).",
				number: "Моля, въведете валиден номер.",
				digits: "Моля, въведете само цифри",
				creditcard: "Моля, въведете валиден номер на кредитна карта.",
				equalTo: "Моля, въведете същата стойност отново.",
				accept: "Моля, въведете стойност с валидно разширение.",
				maxlength: $.validator.format("Моля, въведете повече от {0} символа."),
				minlength: $.validator.format("Моля, въведете поне {0} символа."),
				minWords: $.validator.format("Моля, въведете поне {0} думи."),
				rangelength: $.validator.format("Моля, въведете стойност с дължина между {0} и {1} символа."),
				range: $.validator.format("Моля, въведете стойност между {0} и {1}."),
				max: $.validator.format("Моля, въведете стойност по-малка или равна на {0}."),
				min: $.validator.format("Моля, въведете стойност по-голяма или равна на {0}.")
			});
		}

			$('form[name="frmcontact"]').submit(function()
			{
				var This = $(this);

				if (!This.valid())
				{
					return false;
				}
			});

			$('form[name="frmcontact"]').validate(
			{
				errorElement: 'span',
				errorPlacement: function(error, element)
				{
					console.log(element);
					console.log(element.parent());
					if (element.parent('.switch').length)
					{
						element.parent('.switch').next('.switch_label').next('label.error').remove();
						error.insertAfter(element.parent('.switch').next('.switch_label'));
					}
				}
			});

		if ($('.csc').length)
		{
			$('.csc').click(function()
			{
				var arr_src;

				if ($('.csc > img').attr('src').search('&') !== -1)
				{
					arr_src = $('.csc > img').attr('src').split('&r=');
					$('.csc > img').attr('src', (arr_src[0] + '&r='+Math.floor((Math.random() * 100000) + 1)));
				}
				else
				{
					arr_src = $('.csc > img').attr('src').split('?');
					$('.csc > img').attr('src', (arr_src[0] + '?' + Math.floor((Math.random() * 100000) + 1)));
				}
			});
		}*/
	//}

	//CONTACT FORM VALIDATION & MAIL SENDING....
	$('form[name="admissionform"]').submit(function () {
		
		var This = $(this);
		if($(This).valid()) {
			var action = $(This).attr('action');

			var data_value = unescape($(This).serialize());
			$.ajax({
				 type: "POST",
				 url:action,
				 data: data_value,
				 error: function (xhr, status, error) {
					 confirm('The page save failed.');
				   },
				  success: function (response) {
					$('#ajax_admission_msg').html(response);
					$('#ajax_admission_msg').slideDown('slow');
				 }
			});
		}
		return false;
		
    });
	$('form[name="admissionform"]').validate({
		rules: { 
			txtname: { required: true },
			txtage: { required: true },
			txtcourse: { required: true }
		},
		errorPlacement: function(error, element) { }
	});
	
	//Parallax Sections...
	$('.dt-sc-parallax-section').each(function(){
		$(this).bind('inview', function (event, visible) {
			if(visible == true) {
				$(this).parallax("50%", 0.3, true);
			} else {
				$(this).css('background-position','');
			}
		});
	});
	
	
	
});

// animate css + jquery inview configuration
(function ($) {
    "use strict";
    $(".animate").each(function () {
        $(this).one('inview', function (event, visible) {
            var $delay = "";
            var $this = $(this),
                $animation = ($this.data("animation") !== undefined) ? $this.data("animation") : "slideUp";
            $delay = ($this.data("delay") !== undefined) ? $this.data("delay") : 300;

            if (visible === true) {
                setTimeout(function () {
                    $this.addClass($animation);
                }, $delay);
            } else {
                setTimeout(function () {
                    $this.removeClass($animation);
                }, $delay);
            }
        });
    });
})(jQuery);